import React, { Component } from 'react';
import { Animated, Dimensions, FlatList, Platform, View } from 'react-native';
import PropTypes from 'prop-types';

const binarySearch = (arr, element) => {
    let left = 0;
    let right = arr.length - 1;
    let mid;

    while (left <= right) {
        mid = Math.floor((left + right) / 2);

        const currentY = arr[mid].y;
        const nextY = arr[mid + 1]?.y || Number.MAX_VALUE;  // Handle the last section

        // Match when yOffset is between the current section and the next section
        if (currentY <= element && element < nextY) {
            // Ensure that if the element is very close to the next section, we account for it
            if (nextY - element <= 5) {  // Adding a buffer of 5 units for precision
                return [mid + 1, mid + 1];  // Move to the next section if close enough
            }
            return [mid, mid];
        } else if (currentY < element) {
            left = mid + 1;
        } else {
            right = mid - 1;
        }
    }
    return [left, right];
};



const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
    const paddingToBottom = 20;
    return (
        layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom
    );
};

// Utility function to throttle execution
const throttle = (func, limit) => {
    let lastFunc;
    let lastRan;
    return function(...args) {
        const context = this;
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(() => {
                if (Date.now() - lastRan >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
};


const listViews = [];

class ScrollableTabString extends Component {
    static TAB_POSITION_TOP = 'top';
    static TAB_POSITION_BOTTOM = 'bottom';

    constructor(props) {
        super(props);
        this.state = {
            selectedScrollIndex: 0,
            isPressToScroll: false,
        };
        this.heightTabNames = 0;

        this.goToIndex = this.goToIndex.bind(this);
        this.dataTabNameChildren = this.dataTabNameChildren.bind(this);
        this.dataSectionsChildren = this.dataSectionsChildren.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        const { dataSections, dataTabs, isParent, tabPosition } = this.props;

        if (dataSections.length !== dataTabs.length && !isParent) {
            console.warn(
                "The 'dataSections' and 'dataTabs' length are not equal. This will cause some issues, especially when the section list is scrolling. Consider number of items of those lists to be equal, or add 'isParent' param if you are supporting parent tab - children sections"
            );
        }

        if (
            tabPosition &&
            tabPosition !== ScrollableTabString.TAB_POSITION_BOTTOM &&
            tabPosition !== ScrollableTabString.TAB_POSITION_TOP
        ) {
            console.warn("The tabPosition only accept 'top' or 'bottom' only !");
        }
    }

    componentDidUpdate(prevProps) {
        const { dataSections } = this.props;

        if (dataSections.length > prevProps.dataSections.length) {
            console.warn(
                'Are you loading more items on the dataSections? This component does not support on load more yet!'
            );
        }
    }

    goToIndex(item) {
        const { onPressTab } = this.props;
        //console.log("Item Index: " + item.index);
        // Temporarily block scroll events caused by the tab click
        this.setState({ isPressToScroll: true });

        // Find the minimum Y-axis value for the section associated with the clicked tab
        const findMinYAxis = Math.min(
            ...listViews.filter((i) => i.item.index === item.index).map((ii) => ii.y)
        );
        const res = findMinYAxis && listViews.find((i) => i.y === findMinYAxis);

        if (res) {
            // Scroll to the corresponding section in the main scroll view
            this.tabScrollMainRef?.scrollTo({
                animated: false,
                y: res?.y || 0,  // Scroll to the exact section
            });

            // Set the correct tab index immediately after scrolling
            this.setState({
                selectedScrollIndex: item.index,  // Update state with the correct tab index
            }, () => {
                // After the scroll completes, allow scrolling again
                this.setState({ isPressToScroll: false });
            });
        } else if (item.index === 0) {
            // Handle case where the first tab is clicked
            this.tabScrollMainRef?.scrollTo({ animated: false, y: 0 });
            this.setState({
                selectedScrollIndex: 0,  // Ensure the first tab is selected
            }, () => {
                this.setState({ isPressToScroll: false });
            });
        }

        // Trigger the tab click callback if provided
        onPressTab && onPressTab(item);

        // Prevent the tab bar from scrolling by commenting out the tab scroll action:
        this.tabNamesRef?.scrollToIndex({
            animated: false,
            index: item.index,
            viewPosition: 0.5,
        });

    }



    // map tab item
    dataTabNameChildren({ item, index }) {
        const { renderTabName, selectedTabStyle, unselectedTabStyle } = this.props;
        const { heightTabNames } = this;
        const { selectedScrollIndex } = this.state;

        return React.Children.map(
            renderTabName(item, index, index === selectedScrollIndex),
            (child) =>
                React.isValidElement(child) &&
                React.cloneElement(child, {
                    style: index === selectedScrollIndex ? selectedTabStyle : unselectedTabStyle,
                    onPress: () => {
                        this.goToIndex(item);
                    },
                    onLayout: (e) => {
                        if (heightTabNames === 0) {
                            this.heightTabNames = e.nativeEvent.layout.height;
                        }
                    },
                })
        );
    }

    // map section item
    dataSectionsChildren(item, index) {
        const { renderSection, dataSections } = this.props;

        return React.Children.map(renderSection(item, index), (child) =>
            React.isValidElement(child) &&
            React.cloneElement(child, {
                onLayout: (e) => {
                    const yPos = e.nativeEvent.layout.y;
                    //console.log(`Section ${index} has y offset: ${yPos}`);  // Debugging y positions

                    if (!listViews.find((l) => l.item.index === item.index)) {
                        listViews.push({
                            item: { ...item },
                            y: yPos,
                        });
                    }

                    if (listViews.length >= dataSections.length) {
                        listViews.sort((a, b) => a.y - b.y);  // Ensure the list is sorted by y position
                    }
                },
            })
        );
    }


    onScroll = throttle((e) => {
        const { onScrollSection, dataTabs } = this.props;
        const { isPressToScroll, selectedScrollIndex } = this.state;

        // Call the external onScrollSection callback if it exists
        onScrollSection && onScrollSection(e);

        if (!isPressToScroll) {
            try {
                const yOffset = e.nativeEvent.contentOffset.y;

                // Log scroll offsets and states for debugging
                //console.log("yOffset:", yOffset);

                // Ensure we only set the index to 0 when we're actually at the top
                if (yOffset === 0 && selectedScrollIndex !== 0) {
                    this.setState({ selectedScrollIndex: 0 }, () => {
                        //console.log("Updated selectedScrollIndex: 0");
                    });
                } else {
                    // Perform the binary search to find the correct section based on scroll offset
                    const res = binarySearch(listViews, yOffset);
                    const indexToScrollTo = listViews[res[0]]?.item?.index;

                    //console.log("binarySearch result:", res, "indexToScrollTo:", indexToScrollTo);

                    // Only update the state if the index has actually changed
                    if (indexToScrollTo !== undefined && indexToScrollTo !== selectedScrollIndex) {
                        this.setState({ selectedScrollIndex: indexToScrollTo }, () => {
                            //console.log("Updated selectedScrollIndex:", indexToScrollTo);

                            // Scroll the tab bar to the corresponding tab
                            this.tabNamesRef?.scrollToIndex({
                                animated: true,
                                index: indexToScrollTo,
                                viewPosition: 0.5,
                            });
                        });
                    }
                }
            } catch (err) {
                console.warn('Error in onScroll:', err);
            }
        }
    }, 100);  // Throttling the scroll handler to run at most once every 100 milliseconds




    render() {
        const {
            dataTabs,
            dataSections,
            isParent,
            tabPosition,
            customSectionProps,
            customTabNamesProps,
        } = this.props;
        return (
            <>
                <Animated.ScrollView
                    {...customSectionProps}
                    scrollEventThrottle={16}
                    ref={(ref) => {
                        this.tabScrollMainRef = ref;
                    }}
                    bounces={false}
                    onScrollBeginDrag={() => this.setState({ isPressToScroll: false })}
                    nestedScrollEnabled
                    showsVerticalScrollIndicator={false}
                    scrollEnabled
                    onScroll={this.onScroll}
                    stickyHeaderIndices={tabPosition === 'top' ? [0] : null}
                >
                    {tabPosition === 'top' ? (
                        <View>
                            <FlatList
                                style={{
                                    width: Dimensions.get('window').width - 20,
                                    marginLeft: 10,
                                    height: 38,
                                }}
                                data={dataTabs.map((i, index) => ({ ...i, index }))}
                                keyboardShouldPersistTaps="never"
                                {...customTabNamesProps}
                                ref={(ref) => {
                                    this.tabNamesRef = ref;
                                }}
                                keyExtractor={(item) => item.index.toString()}
                                contentContainerStyle={{
                                    backgroundColor: 'white',
                                }}
                                showsHorizontalScrollIndicator={false}
                                bounces={false}
                                horizontal
                                renderItem={this.dataTabNameChildren}
                                removeClippedSubviews
                                maxToRenderPerBatch={30}
                                windowSize={15}
                                onScrollToIndexFailed={(info) => {
                                    const wait = new Promise((resolve) => setTimeout(resolve, 500));
                                    wait.then(() => {
                                        this.tabNamesRef.scrollToIndex({ index: info.index, animated: true });
                                    });
                                }}
                            />
                        </View>
                    ) : null}
                    <View>
                        {(isParent
                                ? dataSections
                                : dataSections.map((i, index) => ({ ...i, index }))
                        ).map(this.dataSectionsChildren)}
                    </View>
                </Animated.ScrollView>
                {tabPosition === 'bottom' ? (
                    <View>
                        <FlatList
                            style={{ position: 'absolute', bottom: 0 }}
                            keyboardShouldPersistTaps="never"
                            nestedScrollEnabled
                            data={dataTabs.map((i, index) => ({ ...i, index }))}
                            {...customTabNamesProps}
                            contentContainerStyle={{
                                backgroundColor: 'white',
                            }}
                            ref={(ref) => {
                                this.tabNamesRef = ref;
                            }}
                            keyExtractor={(item) => item.index.toString()}
                            showsHorizontalScrollIndicator={false}
                            bounces={false}
                            horizontal
                            renderItem={this.dataTabNameChildren}
                            onScrollToIndexFailed={(info) => {
                                const wait = new Promise((resolve) => setTimeout(resolve, 500));
                                wait.then(() => {
                                    this.tabNamesRef.scrollToIndex({ index: info.index, animated: true });
                                });
                            }}
                        />
                    </View>
                ) : null}
            </>
        );
    }
}

ScrollableTabString.propTypes = {
    dataTabs: PropTypes.array,
    dataSections: PropTypes.array,
    isParent: PropTypes.bool,
    headerTransitionWhenScroll: PropTypes.bool,
    tabPosition: PropTypes.oneOf(['top', 'bottom']),
    renderSection: PropTypes.func.isRequired,
    renderTabName: PropTypes.func.isRequired,
    customTabNamesProps: PropTypes.object,
    customSectionProps: PropTypes.object,
    onPressTab: PropTypes.func,
    onScrollSection: PropTypes.func,
    selectedTabStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    unselectedTabStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ScrollableTabString.defaultProps = {
    dataSections: [],
    dataTabs: [],
    isParent: false,
    headerTransitionWhenScroll: true,
    tabPosition: 'top',
    selectedTabStyle: {
        borderBottomColor: 'black',
        borderBottomWidth: 1,
    },
    unselectedTabStyle: {
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export default ScrollableTabString;