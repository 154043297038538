import React from "react";
import {ActivityIndicator, Dimensions, View} from "react-native";
import {DotIndicator} from "react-native-indicators";

export const LoadingIndicator: React.FC<{ marginTop: number }> = ({marginTop}) => {
    return (
        <View style={{alignItems: "center", marginTop: marginTop}}>
            <DotIndicator size={5} color="#db146d"/>
        </View>
    );
};

export const SubmitIndicator: React.FC<{}> = () => {
    return (
        <ActivityIndicator size="large"
                           style={{position: "absolute",
                               marginTop: Dimensions.get("screen").height / 2 - 30,
                               marginLeft: Dimensions.get("screen").width / 2 - 30}}
                            />
    );
}
