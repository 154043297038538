import React from 'react';
import {NavigationContainer} from "@react-navigation/native";
import Account from "./account";
import {createDrawerNavigator} from "@react-navigation/drawer";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import Shop from "./shop";
import Menu from "./menu";
import Checkout from "./checkout";
import Ordered from "./ordered";
import Cart from "./cart";
import Splash from "./splash";
import {InteractionManager} from "react-native";
import CartView from "./cart";

function ShopStack() {
    const Stack = createNativeStackNavigator();

    const transparentModalOptions = {
        headerShown: false,
        cardStyle: { backgroundColor: "#555", opacity: 0.5 },
        cardOverlayEnabled: true,
    };

    return (
        <Stack.Navigator screenOptions={{ animation: "none" }}>
            <Stack.Group>
                <Stack.Screen name="Shop" component={Shop} options={{headerShown: false}}/>
                <Stack.Screen name="Menu" component={Menu} options={{headerShown: false}} />
                <Stack.Screen name="Checkout" component={Checkout} options={{headerShown: false}} />
                <Stack.Screen name="Ordered" component={Ordered} options={{headerShown: false}} />
            </Stack.Group>
            <Stack.Group screenOptions={{ presentation: "transparentModal", animation: "fade" }}>
                <Stack.Screen name="Cart" component={CartView}
                              options={transparentModalOptions} />
            </Stack.Group>
        </Stack.Navigator>
    );
}

function AppStack() {
    const Drawer = createDrawerNavigator();

    return (
        <Drawer.Navigator useLegacyImplementation={true}>
            <Drawer.Screen name="Home" component={ShopStack} options={{headerShown: false}}/>
            <Drawer.Screen name="Account" component={Account} options={{headerShown: false}}/>
        </Drawer.Navigator>
    );
}

function SplashStack() {
    const Stack = createNativeStackNavigator();

    return (
        <NavigationContainer
            documentTitle={{
                formatter: (options, route) => "Pikashop"
            }}
        >
            <Stack.Navigator screenOptions={{
                headerTitleAlign: "center",
                animation: "none",
            }}>
                <Stack.Screen name="Splash" component={Splash} options={{headerShown: false}}/>
                <Stack.Screen name="AppStack" component={AppStack} options={{headerShown: false}}/>
            </Stack.Navigator>
        </NavigationContainer>
    );
}

export default SplashStack;
