import React, { useEffect, useState } from "react";
import { Modal, StyleSheet, View, TouchableOpacity, TouchableWithoutFeedback, ImageBackground } from "react-native";
import { Box, HStack, Text, Image, Pressable, VStack } from "native-base";
import { useNavigation } from "@react-navigation/native";
import { CateMenuAndOptionData, CateMenuListData, MenuListData, ShopTblData } from "./lib/model";
import ScrollableTabString from "./component/ScrollableTabString";
import apps, { Happyhour, SaleMode } from "./lib/apps";
import * as remote from "./lib/remote";
import { useQuery } from "@tanstack/react-query";
import { LoadingIndicator } from "./component/loading";
import { menuUriByShopid } from "./lib/utils";
import { useRecoilValue } from "recoil";
import { cartState } from "./state/cartState";
import { orderState } from "./state/orderState";
import useScreenWidth from './component/useScreenWidth'; // Adjust the import path as needed

function TitleBar({ shop }: { shop: ShopTblData }) {
    const navigation = useNavigation<any>();

    return (
        <HStack style={styles.titleContainer}>
            <Pressable onPress={() => navigation.openDrawer()}>
                <Image h={26} w={26} source={require("../assets/menu.png")} alt="menu"></Image>
            </Pressable>
            <Text style={styles.shopName}>
                {shop.name}
                {apps.isProd() == false ? ` [Dev]` : ""}
            </Text>
            <Image h={26} w={26} source={require("../assets/account.png")} alt="account"></Image>
        </HStack>
    );
}

function Price({ saleMode, menu }: { saleMode: SaleMode, menu: MenuListData }) {
    if (apps.isSale(saleMode, menu)) {
        return (
            <HStack>
                <Text style={styles.menuPriceStroked}>${menu.price}</Text>
                <Text style={styles.specialPrice}>${apps.getPrice(saleMode, menu)}</Text>
            </HStack>
        );
    }

    return (
        <Text style={styles.menuPrice}>${menu.price}</Text>
    );
}

function CateAndMenu({
                         cateMenuList, shopid, saleMode, onClick,
                     }: {
    cateMenuList: CateMenuListData[], shopid: string, saleMode: SaleMode, onClick: (m: MenuListData) => void
}) {

    const tabNames = cateMenuList.map(cm => {
        return { title: cm.cateName };
    });

    return (
        <ScrollableTabString
            dataTabs={tabNames}
            dataSections={cateMenuList}
            renderSection={(cateMenu: CateMenuListData) => {
                return (
                    <VStack key={cateMenu.cateId} style={cateMenu.cateId === cateMenuList[0].cateId ? styles.cateAndMenu : styles.empty}>
                        <Box style={styles.sectionBox}>
                            <Text style={styles.sectionText}>{cateMenu.cateName}</Text>
                        </Box>
                        {cateMenu.menus.map((m: MenuListData) => (
                            <TouchableWithoutFeedback key={m.id} onPress={() => { if (!m.soldOut) { // Only trigger onClick if not sold out
                                onClick(m);
                            } }} disabled={m.soldOut} // Disable interaction if sold out
                            >
                                <HStack key={m.id} mt="10px" pl="20px" pr="10px" pb="9px" style={styles.menuContainer}>
                                    <VStack flex="1">
                                        <Text style={styles.menuName}>{m.name} {m.soldOut}</Text>
                                        <Price saleMode={saleMode} menu={m} />
                                        <Text style={styles.menuDescr}>{m.descr}</Text>
                                    </VStack>
                                    <Box style={styles.menuImageContainer}>
                                        <Image source={menuUriByShopid(shopid, m)} alt="" resizeMode="cover" style={styles.menuImage} />
                                        {
                                            m.soldOut ? (
                                                // Show "Sold Out" image or label
                                                <Image
                                                    source={require("../assets/soldout.png")} // Replace with your "sold out" image
                                                    alt="Sold Out"
                                                    style={styles.soldOutOverlay} // Add appropriate styling
                                                />
                                            ) : (
                                                apps.isSale(saleMode, m) &&
                                                    <Image source={require("../assets/icon_sale.png")} alt="sale" w="40px" h="18px" ml="2" mt="0" resizeMode="cover" />
                                            )
                                        }

                                    </Box>
                                </HStack>
                            </TouchableWithoutFeedback>
                        ))}
                    </VStack>
                );
            }}
            renderTabName={(item: { title: string }, index: number, selected: boolean) => (
                <TouchableOpacity>
                    {selected ?
                        <HStack mr="15px">
                            <Image source={require("../assets/bracket_L.png")} alt="left" width="8px" height="23px" resizeMode="cover" />
                            <Text style={styles.selectedTabText}>
                                {item.title}
                            </Text>
                            <Image source={require("../assets/bracket_R.png")} alt="right" w="8px" h="23px" />
                        </HStack>
                        :
                        <Text style={styles.unselectedTabText}>
                            {item.title}
                        </Text>}
                </TouchableOpacity>
            )}
            selectedTabStyle={styles.selectedTabStyle}
            unselectedTabStyle={styles.unselectedTabStyle}
            customTabNamesProps={styles.tabNames}
            customSectionProps={styles.customSectionProps}
        />
    );
}

function TableNo() {
    const yourOrder = useRecoilValue(orderState);

    return (
        <VStack style={styles.tableNoContainer}>
            <Box style={styles.tableNoBox}>
                <Text style={styles.tableNo}>
                    {yourOrder.getOrderInfoLong()}
                </Text>
            </Box>
            {(apps.isTakeaway() && apps.currentShop.descr.length > 0) && <Box>
                <Text style={styles.shopDescr}>
                    {apps.currentShop.descr}
                </Text>
            </Box>}
        </VStack>
    );
}

function ViewCartPopup() {
    const cart = useRecoilValue(cartState);
    const navigation = useNavigation<any>();
    const [isCartView, setIsCartView] = useState(false);

    useEffect(() => {
        if (isCartView) {
            navigation.navigate("Cart");
        }
        return () => {
            setIsCartView(false);
        };
    }, [isCartView]);

    if (cart.count == 0) return null;

    return (
        <TouchableWithoutFeedback onPress={() => { setIsCartView(true); }}>
            <Box style={styles.viewCartPopup}>
                <ImageBackground source={require("../assets/icon_bag.png")}
                                 style={styles.iconBag} resizeMode="contain">
                    <Text style={styles.cartCount}>{cart.count}</Text>
                </ImageBackground>
            </Box>
        </TouchableWithoutFeedback>
    );
}

function HappyHour() {
    const happyhour: Happyhour = apps.getHappyhour();

    return (
        <ImageBackground source={require("../assets/salemodeback.png")} style={styles.happyhourBack} resizeMode="stretch">
            <Text style={styles.happyhourText}>{apps.getHappyhourDescr(happyhour.saleMode)}</Text>
            <Text style={styles.happyhourSubtext}>{apps.getHappyhourTime(happyhour)}</Text>
        </ImageBackground>
    );
}

function Shop({ navigation }) {
    const screenWidth = useScreenWidth();
    const currentShop = apps.currentShop;
    const [showNotice, setShowNotice] = useState(false);

    useEffect(() => {
        if (currentShop.shopNotice) {
            setShowNotice(true);
        }
    }, [currentShop]);

    const { isLoading, error, data } = useQuery<CateMenuAndOptionData>(["cateMenuAndOption"], () => remote.getCateMenuAndOptionList(currentShop.id, apps.tableNo));

    if (error) return (<Text>"An error has occurred: " + error.message</Text>);

    if (data) {
        apps.options = data.options;
    }

    const menuSelected = (m: MenuListData) => {
        apps.selectedMenu = m;
        navigation.navigate("Menu");
    };

    const saleMode = apps.getHappyhour().saleMode;
    apps.saleMode = saleMode;

    const isDesktop = screenWidth > 768;
    const containerStyle = isDesktop ? styles.containerDesktop : styles.containerMobile;

    return (
        <View style={containerStyle}>
            <TitleBar shop={apps.currentShop} />
            {saleMode != SaleMode.NORMAL && <HappyHour />}
            <TableNo />
            {isLoading && LoadingIndicator({ marginTop: 200 })}
            {data && <CateAndMenu cateMenuList={data.cateMenus} shopid={currentShop.shopid} saleMode={saleMode} onClick={menuSelected} />}
            <ViewCartPopup />
            {showNotice && (
                <Modal
                    transparent={true}
                    animationType="slide"
                    visible={showNotice}
                    onRequestClose={() => setShowNotice(false)}
                >
                    <View style={styles.modalBackground}>
                        <View style={styles.modalContainer}>
                            <Text style={styles.modalMessage}>{currentShop.shopNoticeDescr}</Text>
                            <br />
                            <TouchableOpacity
                                style={styles.modalButton}
                                onPress={() => setShowNotice(false)}
                            >
                                <Text style={styles.modalButtonText}>Close</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </Modal>
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    containerMobile: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    containerDesktop: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginHorizontal: '0%',  // Updated to 0%
    },
    titleContainer: {
        width: "100%",
        height: 51,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: "#fff",
        justifyContent: "space-between",
        alignItems: "center",
    },
    shopName: {
        fontFamily: "NotoSans-Bold",
        fontSize: 22,
        fontWeight: "900",
        color: "#434343",
        letterSpacing: -0.55,
    },
    tabNames: {
        width: "100%",
        backgroundColor: "white",
        marginTop: 0,
        paddingLeft: 20,
        paddingBottom: 15,
        marginRight: 0,
        marginBottom: -5,
    },
    sectionBox: {
        marginTop: -1,
        backgroundColor: "#f7f5f2",
        width: "100%",
        height: 42,
        borderTopColor: "#dbd3c6",
        borderTopWidth: 1,
        borderBottomColor: "#dbd3c6",
        borderBottomWidth: 1,
        justifyContent: "center",
        paddingLeft: 20,
    },
    sectionText: {
        fontFamily: "NotoSans-Bold",
        fontSize: 16,
        fontWeight: "900",
        color: "#db146d",
        letterSpacing: -0.4,
    },
    menuName: {
        fontFamily: "NotoSans-Regular",
        color: "#434343",
        fontWeight: "600",
        fontSize: 15,
        letterSpacing: -0.6,
    },
    menuPrice: {
        fontFamily: "NotoSans-Regular",
        color: "#db146d",
        fontSize: 16,
    },
    menuDescr: {
        fontFamily: "NotoSans-Regular",
        color: "#999",
        fontSize: 12,
        lineHeight: 18,
    },
    menuImage: {
        position: "absolute",
        top: 0,
        left: 0,
        width: 90,
        height: 90,
        marginLeft: 10,
        borderRadius: 10,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#bcaf9a",
    },
    menuContainer: {
        borderBottomWidth: 1,
        borderColor: "#dbd3c6",
    },
    menuImageContainer: {
        width: 90,
        height: 90,
        marginLeft: 10,
        marginRight: 10,
    },
    cateAndMenu: {
        borderTopWidth: 1,
        borderColor: "#dbd3c6",
    },
    empty: {},
    tableNoContainer: {
        width: "100%",
        minHeight: 59,
        backgroundColor: "#f7f5f2",
        borderTopWidth: 1,
        borderTopColor: "#dbd3c6",
        borderStyle: "solid",
        alignItems: "center",
        justifyContent: "center",
    },
    tableNoBox: {
        width: 264,
        height: 38,
        backgroundColor: "#fff",
        borderWidth: 1,
        borderRadius: 10,
        borderColor: "#ebe6df",
        alignItems: "center",
        justifyContent: "center",
    },
    tableNo: {
        fontFamily: "NotoSans-Regular",
        fontSize: 13,
        fontWeight: "700",
        letterSpacing: -0.33,
        color: "#bcaf9a",
    },
    menuPriceStroked: {
        fontFamily: "NotoSans-Regular",
        color: "#999",
        fontSize: 16,
        textDecorationLine: "line-through"
    },
    specialPrice: {
        marginLeft: 5,
        fontFamily: "NotoSans-Regular",
        fontWeight: "700",
        color: "red",
        fontSize: 16,
    },
    happyhourBack: {
        width: "100%",
        height: 50,
        justifyContent: "center",
        alignItems: "center",
    },
    happyhourText: {
        fontFamily: "NotoSans-Regular",
        fontWeight: "900",
        color: "#fff",
        fontSize: 16,
    },
    happyhourSubtext: {
        fontFamily: "NotoSans-Regular",
        fontWeight: "500",
        color: "#fff",
        fontSize: 13,
    },
    shopDescr: {
        fontSize: 14,
        letterSpacing: -0.3,
        alignItems: "center",
        textAlign: "left",
        marginLeft: 30,
        marginRight: 30,
        marginTop: 3,
        marginBottom: 5,
        color: "#666",
    },
    selectedTabText: {
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        color: "#434343",
    },
    unselectedTabText: {
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        marginRight: 15,
        color: "#bcaf9a",
    },
    selectedTabStyle: {
        alignItems: "center",
        justifyContent: "center",
    },
    unselectedTabStyle: {
        alignItems: "center",
        justifyContent: "center",
    },
    customSectionProps: {
        width: "100%",
        padding: 0,
    },
    viewCartPopup: {
        backgroundColor: "#db146d",
        width: 58,
        height: 58,
        position: "absolute",
        top: "88%",
        left: "80%",
        justifyContent: "center",
        alignSelf: "center",
        alignItems: "center",
        borderRadius: 20,
        shadowOffset: { width: 2, height: 4 },
        shadowColor: "#00000070",
        shadowOpacity: 0.8,
        borderWidth: 1,
        borderColor: "#e36ca1",
    },
    iconBag: {
        width: 38,
        height: 30,
        paddingTop: 7,
        alignItems: "center",
        justifyContent: "center",
    },
    cartCount: {
        color: "white",
        fontSize: 13,
        fontWeight: "bold",
        letterSpacing: -0.3,
    },
    modalBackground: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        width: '80%',
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        alignItems: 'center',
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    modalMessage: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 20,
    },
    modalButton: {
        backgroundColor: '#db146d',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
    },
    modalButtonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
    },
    soldOutOverlay: {
        position: 'absolute',
        top: 20,
        left: 10,
        width: '100%',  // Match the menu image size
        height: 50, // Match the menu image size
        opacity: 1,  // Optional: to give it a transparent overlay effect
        zIndex: 2,  // Ensure the sold out image is on top
    },

});

export default Shop;
