/**
 * Order Data
 */
import { OrderType, PayMethod } from "./model";
import {SaleMode} from "./apps";

export class Order {
  shopId: number;
  userId: number;
  type: OrderType;
  qrType: string;
  payMethod: PayMethod;
  tableNo: string;
  txId: number;      // tabletious ord no
  total: number;    // 합계금액. 서버에서 다시 계산해서 체크할 것
  items: OrderItem[];

  name: string;   // for takeaway order
  mobile: string; // for takeaway order

  constructor(shopId: number, userId: number, orderType: OrderType, qrType: string, payMethod: PayMethod,
              tableNo: string, txId: number, total: number, items: OrderItem[],
              name: string, mobile: string) {
    this.shopId = shopId;
    this.userId = userId;
    this.type = orderType;
    this.qrType = qrType;
    this.payMethod = payMethod;
    this.tableNo = tableNo;
    this.txId = txId;
    this.total = total;
    this.items = items;
    this.name = name;
    this.mobile = mobile;
  }
}

export class OrderItem {
  menuId: number;
  qty: number;
  options: string;  // tagOptions()에 의해서 만들어지는 option 선택 값
  itemPrice: number;  // menu.price + sum(selected_option_item.price)
  saleMode: SaleMode;

  constructor(menuId: number, qty: number, options: string, itemPrice: number, saleMode: SaleMode) {
    this.menuId = menuId;
    this.qty = qty;
    this.options = options;
    this.itemPrice = itemPrice;  // menu.price + sum(selected_option_item.price)
    this.saleMode = saleMode;
  }
}

export enum OrderStatus {
  PLACED = "PLACED",
  SENT = "SENT",
  CONFIRMED = "CONFIRMED",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED",
}

export class OrderPlaceRes {
  orderId: number;
  orderStatus: OrderStatus;

  constructor(orderId: number, orderStatus: OrderStatus) {
    this.orderId = orderId;
    this.orderStatus = orderStatus;
  }
}

/**
 * order status:
 * PLACED,
 *     SENT,
 *     CONFIRMED,
 *     REJECTED,
 *     CANCELED;
 */
export class OrderStatusRes {
  orderId: number;
  ordNo: number;
  orderStatus: string;
  error: string;

  constructor(orderId: number, orderStatus: string, error: string) {
    this.orderId = orderId;
    this.orderStatus = orderStatus;
    this.error = error;
  }
}


