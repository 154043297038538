import React, {memo, useEffect, useState} from "react";
import {View, Text, StyleSheet, TouchableWithoutFeedback, FlatList} from "react-native";
import {Button, VStack, Image, HStack, Box} from "native-base";
import { useNavigation } from "@react-navigation/native";
import DashedLine from "react-native-dashed-line";
import {Cart, mapToItems, tagOptions} from "./lib/model";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {cartState} from "./state/cartState";
import apps, {SaleMode} from "./lib/apps";
import {orderState} from "./state/orderState";
import {useMutation} from "@tanstack/react-query";
import {Order} from "./lib/order_model";
import * as remote from "./lib/remote";

function Title({ isCardPayment }) {
    const navigation = useNavigation<any>();
    const [cart, setCart] = useRecoilState(cartState);

    return (
        <HStack style={styles.titleContainer}>
            <TouchableWithoutFeedback onPress={() => {
                setCart(new Cart(apps.getHappyhour().saleMode, apps.currentShop!!))
                apps.getOrdIdAndClear();
                apps.getOrdNoAndClear();
                navigation.popToTop();
            }}>
                <Image source={require("../assets/back.png")} style={styles.titleBack} alt="back" />
            </TouchableWithoutFeedback>
            <Text style={styles.title}>{isCardPayment ? 'Receipt' : 'Ordered'}</Text>
        </HStack>
    );
}

function Thanks({ordId, ordNo, isCardPayment}) {
    //const [yourOrder, setYourOrder] = useRecoilState(orderState);
    const yourOrder = useRecoilValue(orderState);
    const [displayTime, setDisplayTime] = useState("");

    useEffect(() => {
        const now = new Date();
        const formattedTime = now.toLocaleTimeString(); // Adjust format as needed
        setDisplayTime(formattedTime);
    }, []);

    return (
        <VStack style={{ backgroundColor: "#f7f5f2", borderTopColor: "#dbd3c6", borderTopWidth: 1 }}>
            <Box style={{ height: 176 }}>
                <Image source={require("../assets/thanks.png")} alt="thanks" w={360} h={158} />
            </Box>
            <Box style={{ height: 120, alignItems: "center" }}>
                <Text style={{
                    paddingTop: 10, fontSize: 30, color: "#db146d", fontWeight: "900", letterSpacing: -0.75,
                    width: 190, height: 75, lineHeight: 30, textAlign: "center",
                }}>THANKS FOR ORDERING</Text>
                <Text style={{
                    color: "#a0927d", fontSize: 16, fontWeight: "900", letterSpacing: -0.4, marginBottom: 10,
                }}>
                    {yourOrder.getOrderInfoLong()}
                </Text>
            </Box>
            <Box style={{height: 90, alignItems: "center", justifyContent: "flex-start"}}>

                <Text style={{color: "#a0927d", fontSize: 14, fontWeight: "900", letterSpacing: -0.4, marginBottom: 3}}>Order Number</Text>
                <Text style={{fontSize: 60, color: "#db146d", fontWeight: "900", letterSpacing: -0.75, lineHeight: 50, marginBottom: 15}}>
                    {ordNo}
                </Text>
                <Text style={{color: "#a0927d", fontSize: 12, fontWeight: "900", letterSpacing: 1, marginBottom: 0}}>{displayTime} - {ordId}</Text>
            </Box>
            {!isCardPayment && (
                <Box style={{ height: 80, alignItems: "center", justifyContent: "flex-start", padding: 20 }}>
                    <Text style={{ color: "#db146d", fontSize: 23, fontWeight: "900", letterSpacing: -0.4, marginBottom: 3, textAlign: "center" }}>
                        {(apps.currentShop != null && apps.currentShop.orderedMsg != null && apps.currentShop.orderedMsg.trim() !== "") ? apps.currentShop.orderedMsg : "Pay at the counter"}
                    </Text>
                </Box>
            )}
            {(apps.currentShop != null && apps.currentShop.shopid == "sushikiyocpr" && apps.currentShop.tblNo.startsWith("Q")) &&
                <Box style={{height: 80, alignItems: "center", justifyContent: "flex-start", padding: 20}}>
                    <Text style={{color: "#db146d", fontSize: 18, fontWeight: "900", letterSpacing: -0.4, marginBottom: 3, textAlign: "center"}}>
                        Please pay at the counter first after placing your order
                    </Text>
                </Box>
            }
            <Image source={require("../assets/paperbottom.png")} alt="pb" h="18px" w="100%" />
        </VStack>

    );
}

function Total({ subtotal, fee, total }) {
    return (
        <VStack style={styles.totalContainer}>
            <HStack style={styles.totalRow}>
                <Text style={styles.totalLabel}>SubTotal</Text>
                <Text style={styles.totalPrice}>{subtotal}</Text>
            </HStack>
            <HStack style={styles.totalRow}>
                <Text style={styles.totalLabel}>Processing Fee</Text>
                <Text style={styles.totalPrice}>{fee}</Text>
            </HStack>
            <HStack style={styles.totalRowLast}>
                <Text style={styles.totalLabel}>Total</Text>
                <Text style={styles.totalPrice}>{total}</Text>
            </HStack>
        </VStack>
    );
}

function ItemsAndBottom({ subtotal, fee, total, isCardPayment, cart}) {
    const navigation = useNavigation<any>();
    return (
        <VStack style={{ ...styles.container, flex: 1 }}>
            <Box style={{
                height: 40,
                justifyContent: "center",
                borderBottomColor: "#434343",
                borderBottomWidth: 1,
            }}>
                <Text style={{
                    fontSize: 16,
                    fontWeight: "900",
                    color: "#434343",
                }}>Your items</Text>
            </Box>
            <FlatList data={mapToItems(cart.items)} scrollEnabled={true} showsVerticalScrollIndicator={false}
                      ListFooterComponent={
                          <>
                              <Total subtotal={subtotal} fee={fee} total={total} />
                          </>
                      }

                      renderItem={({ item, index }) => {
                          const isLast = index === cart.items.length - 1;

                          return (
                              <VStack style={{ width: "100%", backgroundColor: "white" }}>
                                  <HStack style={styles.itemList}>
                                      <VStack style={styles.itemLeft}>
                                          <Text style={styles.itemName}>{item.name}</Text>
                                          <Text style={styles.itemOptions}>{item.optionsDescr()}</Text>
                                      </VStack>
                                      <VStack style={styles.itemRight}>
                                          <Text style={styles.itemPrice}>${item.total.toFixed(1)}</Text>
                                          <Box style={styles.itemQtyBox}>
                                              <Text style={styles.itemQty}>x{item.qty}</Text>
                                          </Box>
                                      </VStack>
                                  </HStack>
                                  {!isLast ?
                                      <DashedLine dashLength={6} dashGap={6} dashColor="#434343" style={{ height: 1 }} /> : null
                                  }
                              </VStack>
                          );
                      }
                      } />
        </VStack>
    );
}


    function Ordered({navigation}) {
        const cart = useRecoilValue(cartState);
        const [ordNo, setOrdNo] = useState(0);
        const [ordId, setOrdId] = useState(0);
        const [subtotal, setSubtotal] = useState('$' + cart.total().toFixed(2).toString());
        const [fee, setFee] = useState('$0.00');
        const [total, setTotal] = useState('$' + cart.total().toFixed(2).toString());
        const [isCardPayment, setIsCardPayment] = useState(false);

        const receipt = useMutation((orderId: number) => remote.findReceiptByOrderId(orderId), {
            onSuccess: (res) => {
                if (res && Object.keys(res).length > 0) {
                     setSubtotal(res.originalAmount);
                     setFee(res.surchargeAmount);
                     setTotal(res.total);
                     setIsCardPayment(true);
                }

                setOrdNo(apps.getOrdNoAndClear());
                setOrdId(apps.getOrdIdAndClear());

            },
            onError: (err) => {
                // Handle error
            },
            onMutate: (orderId) => {
                // On mutate logic if needed
            },
        });

        useEffect(() => {
            if (apps.ordId) {
                receipt.mutate(apps.ordId);
            }
        }, []);

        //console.log("ordNo: " + ordNo);

        return (
            <VStack style={{ flex: 1, backgroundColor: "white" }}>
                <Title isCardPayment={isCardPayment} />
                <Thanks ordId={ordId} ordNo={ordNo} isCardPayment={isCardPayment} />
                <ItemsAndBottom
                    subtotal={subtotal}
                    fee={fee}
                    total={total}
                    isCardPayment={isCardPayment}
                    cart={cart}
                />
            </VStack>
        )
    }


const styles = StyleSheet.create({
    container: {
        marginTop: 15,
        marginLeft: 20,
        marginRight: 20,
    },
    titleContainer: {
        height: 49,
        backgroundColor: "white",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: 20,
    }, title: {
        fontSize: 22,
        fontWeight: "900",
        color: "#434343",
        letterSpacing: -0.55,
        lineHeight: 35,
    }, titleBack: {
        height: 26, width: 26,
        marginTop: 5, marginRight: 10,
    },
    itemList: {
        width: "100%", backgroundColor: "white", height: 59,
        // borderBottomWidth: 1, borderBottomColor: "#434343",
        paddingTop: 6,
    }, itemLeft: {
        flex: 1, justifyContent: "center",
    }, itemName: {
        fontSize: 14,
        fontWeight: "bold",
        letterSpacing: -0.3,
        color: "#434343",
    }, itemOptions: {
        fontSize: 12,
        letterSpacing: -0.3,
        color: "#999",
    }, itemRight: {
        justifyContent: "flex-start", alignItems: "flex-end",
    }, itemPrice: {
        fontSize: 16,
        color: "#434343",
        letterSpacing: -0.3,
    }, itemQtyBox: {
        marginTop: 3,
        borderRadius: 9,
        borderWidth: 0,
        backgroundColor: "#999999",
        paddingHorizontal: 5,
        paddingVertical: 0,
        width: 25,
        height: 20,
        alignItems: "center",
        justifyContent: "center",
    }, itemQty: {
        marginTop: -2,
        fontSize: 12,
        fontWeight: "bold",
        color: "white",
    },
    totalContainer: {
        backgroundColor: "white",
    }, totalRow: {
        height: 39, width: "100%",
        borderTopWidth: 1, borderTopColor: "#434343", alignItems: "center",
    }, totalLabel: {
        flex: 1, justifyContent: "center",
        fontSize: 16, fontWeight: "900", color: "#434343", letterSpacing: -0.4,
    }, totalPrice: {
        justifyContent: "center", alignItems: "flex-end", fontSize: 16, letterSpacing: -0.3,
    }, totalRowLast: {
        height: 39, width: "100%",
        borderTopWidth: 1, borderTopColor: "#434343", alignItems: "center",
        borderBottomWidth: 1, borderBottomColor: "#434343",
    },

});

export default Ordered;
