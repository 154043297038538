import React from "react";
import {StyleSheet} from 'react-native';
import {RecoilRoot} from "recoil";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {extendTheme, NativeBaseProvider} from "native-base";
import AppStack from "./src/app-navigation";
import "react-native-gesture-handler";
import Splash from "./src/splash";
import SplashStack from "./src/app-navigation";
import {useResolvedFontFamily} from "native-base/lib/typescript/hooks/useResolvedFontFamily";
import {useFonts} from "expo-font";
import FlashMessage from "react-native-flash-message";

/** Customized Theme **/
const theme = extendTheme({
        colors: {
            primary: {
                900: "#db146d",
                800: "#e82e80",
            },
            light: {
                900: "#434343",
                800: "#bcaf9a",
                700: "#dbd3c6",
                600: "#eae3d9",
                500: "#f7f5f2",
            },
            gray: {
                900: "#222222",
                800: "#555555",
                700: "#434343",
                600: "#999999",
                500: "#cccccc",
            },
        },
        fontConfig: {
            NotoSans: {
                900: {
                    normal: "NotoSans-Black",
                },
                700: {
                    normal: "NotoSans-Bold",
                },
                500: {
                    normal: "NotoSans-Regular",
                },
                400: {
                    normal: "NotoSans-Regular",
                },
                300: {
                    normal: "NotoSans-Regular",
                },
                200: {
                    normal: "NotoSans-Regular",
                },
                100: {
                    normal: "NotoSans-Regular",
                },
            },
        },
        fonts: {
            heading: "NotoSans",
            body: "NotoSans",
            mono: "NotoSans",
        },
        config: {
            initialColorMode: "white",
        },
    })
;

export default function App() {
    const queryClient = new QueryClient();

    const [fontsLoaded] = useFonts({
        "NotoSans-Black": require("./assets/fonts/NotoSans-Black.ttf"),
        "NotoSans-Bold": require("./assets/fonts/NotoSans-Bold.ttf"),
        "NotoSans-Regular": require("./assets/fonts/NotoSans-Regular.ttf"),
    });

    if (!fontsLoaded) {
        return null;
    }

    return (
        <RecoilRoot>
            <QueryClientProvider client={queryClient}>
                <NativeBaseProvider theme={theme}>
                    <SplashStack/>
                    <FlashMessage position="center" backgroundColor={'black'} />
                </NativeBaseProvider>
            </QueryClientProvider>
        </RecoilRoot>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
